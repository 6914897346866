import { Area, Bar, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartDisplay, DynamicChartData, Filters } from "../../types"
import * as Enums from "../../util/Enums";
import { Fragment, useMemo } from "react";
import { presetColors } from "../../util/Colours";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";


type GraphAreaProps = {
    chartData: DynamicChartData[];
    filters: Filters;
    chartDisplay: ChartDisplay;
    groups: string[];
    chartRef: React.MutableRefObject<HTMLDivElement | null>;
}

function GraphArea({chartData, filters, chartDisplay, groups, chartRef} : GraphAreaProps) {
    const getDayNightHex = (date: Date, i: number) : string => {
        //!!! Not sure why it needs utc hours here to format correctly - worth having a look into
        return date.getUTCHours() >= 0 && date.getUTCHours() <= 6 ? darkenHex(getColor(i), 30) : getColor(i);
    };

    const getColor = (index: number) => presetColors[index % presetColors.length];

    function darkenHex(hex: string, percent: number) : string {
        // Remove the '#' character if present
        hex = hex.replace(/^#/, '');
        
        // Parse the hex color into its RGB components
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);
        
        // Calculate the amount to darken each component by
        r = Math.floor(r * (1 - percent / 100));
        g = Math.floor(g * (1 - percent / 100));
        b = Math.floor(b * (1 - percent / 100));
        
        // Convert each component back to a two-digit hex string
        const rS = r.toString(16).padStart(2, '0');
        const gS = g.toString(16).padStart(2, '0');
        const bS = b.toString(16).padStart(2, '0');
        
        // Concatenate the darkened components and return the result
        return `#${rS}${gS}${bS}`;
    }

    const unit = useMemo(() => {
        const maxValue = Math.max(
            ...chartData.flatMap(data =>
                groups.map(group => {
                    const value = data[group];
                    return typeof value === 'number' ? value : 0; // Ensure we only take numeric values
                })
            )
        );
        if (maxValue >= 1e6) {
            return 'GWh';
        } else if (maxValue >= 1e3) {
            return 'MWh';
        } else {
            return 'kWh';
        }
    }, [chartData, groups]);


    const numberFormatter = (value: number) => {
        if (unit === 'GWh') {
            return `${(value / 1e6).toFixed(2)}${unit}`; // Format as GWh
        } else if (unit === 'MWh') {
            return `${(value / 1e3).toFixed(2)}${unit}`; // Format as MWh
        } else {
            return `${value.toFixed(2)}${unit}`; // Format as kWh
        }
    };

    const dateFormatter = (value: any) => {
        if (!moment(value, moment.ISO_8601, true).isValid())
            return value;

        switch (filters?.period) {
            case Enums.PeriodEnum.HOURLY:
                return moment(value).format('DD/MM/YYYY HH:mm');
            case Enums.PeriodEnum.DAILY:
                return moment(value).format('DD/MM/YYYY');
            case Enums.PeriodEnum.WEEKLY:
                return moment(value).format('DD/MM/YYYY');
            case Enums.PeriodEnum.MONTHLY:
                if (!filters.startDate || !filters.endDate) {
                    return moment(value).format('MMM YYYY');
                } else {
                    const startDate = moment(filters.startDate);
                    const endDate = moment(filters.endDate);
                    const monthDiff = endDate.diff(startDate, 'months');
                    return monthDiff < 12 ? moment(value).format('MMM') : moment(value).format('MMM YYYY');
                }
            case Enums.PeriodEnum.YEARLY:
                return moment(value).format('YYYY');
            default:
                return value;
        }
    };


    return (
        <ResponsiveContainer ref={chartRef} width="100%" height="100%">
            <ComposedChart data={chartData} margin={{left: 40 }}>
                <XAxis dataKey="date" tickFormatter={dateFormatter} />
                <YAxis tickFormatter={numberFormatter} />
                <Tooltip formatter={numberFormatter} labelFormatter={dateFormatter} />
                <Legend />
                {groups.map((group, index) => (
                    <Fragment key={uuidv4()}>
                        {chartDisplay?.chartType === Enums.ChartTypeEnum.LINE &&
                            <Area strokeWidth={3} type="monotone" dataKey={group} name={group} key={group} stroke={getColor(index)} fillOpacity={0.3} fill={getColor(index)}/>
                        }
                        {/* !!! This is just an example of day/night overlay - need an improved method for production  */}
                        {chartDisplay?.chartType === Enums.ChartTypeEnum.BAR && !(filters?.period === Enums.PeriodEnum.HOURLY && chartDisplay?.overlays.includes(Enums.OverlayEnum.DAY_NIGHT)) &&
                            <Bar type="monotone" dataKey={group} name={group} key={group} fill={getColor(index)} />
                        }
                        {chartDisplay?.chartType === Enums.ChartTypeEnum.BAR && filters?.period === Enums.PeriodEnum.HOURLY && chartDisplay?.overlays.includes(Enums.OverlayEnum.DAY_NIGHT) &&
                            <Bar type="monotone" dataKey={group} name={group} key={group} fill={getColor(index)} >
                                {chartData.map((entry, i) => (
                                    <Cell fill={getDayNightHex(entry.date, index)} key={`cell-${i}`} />
                                ))}
                            </Bar>
                        }
                    </Fragment>
                ))}
                {chartDisplay?.overlays.includes(Enums.OverlayEnum.TREND) && 
                    <Line strokeDasharray={"5 5"} type="monotone" dataKey="trend" name="Trend" stroke="red" dot={false} />
                }
                {chartDisplay?.overlays.includes(Enums.OverlayEnum.AVERAGE) && 
                    <Line strokeDasharray={"5 5"} type="monotone" dataKey="overallAverage" name="Average" stroke="blue" dot={false} />
                }
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default GraphArea
