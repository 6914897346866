import { IconButton } from "@chakra-ui/react";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { usePeristentGrid } from "../../hooks/usePersistentGrid";

export default function PersistentGridResetButton() {
    const { reset } = usePeristentGrid();

    return (
        <IconButton icon={<FaArrowRotateLeft />} onClick={() => reset()} aria-label={"Reset"}>Reset</IconButton>
    );
}