// NotificationStack.tsx
import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { Alert, AlertIcon, VStack } from '@chakra-ui/react';
import { Notification, NotificationStatus } from '../types'

interface NotificationContextType {
  pushNotification: (message: string, status?: NotificationStatus, duration?: number) => void;
}

// Create the context with a proper type or `null` for initial value
export const NotificationContext = createContext<NotificationContextType | null>(null);

interface NotificationStackProps {
  children: ReactNode;
}

const NotificationPane: React.FC<NotificationStackProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const pushNotification = (message: string, status: NotificationStatus = 'info', duration: number = 5000) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, status }]);

    // Remove notification after the duration
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, duration);
  };

  // Use useEffect to clear notifications when children change
  useEffect(() => {
    setNotifications([]);
  }, [children]);


  return (
    <NotificationContext.Provider value={{ pushNotification }}>
      {children}
      <VStack
        spacing={4}
        position="fixed"
        top={4}
        left="50%"
        transform="translateX(-50%)"
        zIndex={1000}
      >
        {notifications.map(({ id, message, status }) => (
          <Alert key={id} status={status} variant="solid">
            <AlertIcon />
            {message}
          </Alert>
        ))}
      </VStack>
    </NotificationContext.Provider>
  );
};

export default NotificationPane;
