import { useRef, useState } from "react";
import { Button, Popover, PopoverArrow, PopoverContent, PopoverTrigger, PopoverCloseButton, PopoverBody, FormControl, FormLabel, NumberInput, NumberInputField, useDisclosure, ButtonGroup, Spinner, Link, Icon, VStack, Box } from "@chakra-ui/react"
import { useMsal, useAccount } from "@azure/msal-react";
import { getAccessToken } from "../auth/authFunctions";
import DatePicker from "react-datepicker"
import '../styles/datepicker-custom.css';
import  FocusLock from "react-focus-lock"
import { SupplyType } from "../types";
import moment from "moment";
import { FaTachometerAlt } from "react-icons/fa";

type MeterReadSubmissionProps = {
  supplyData: SupplyType
}

type meterReadValueType = {
  date: Date | null,
  read: number | undefined
}

function MeterReadSubmission({supplyData}: MeterReadSubmissionProps) {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [createTicketState, setCreateTicketState] = useState("")

  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = useRef(null)
  const [meterReadValues, setMeterReadValues] = useState<meterReadValueType>({date: new Date(), read: undefined});
  // date of read needs to be today or in the past and read 0 or more for submit button to show
  const submitButtonDisabled = !(meterReadValues.date !== null && meterReadValues.date <= new Date() && meterReadValues.read !== undefined && meterReadValues.read >= 0)

  async function handleFormSubmit(){

    onClose(); // closes popover
    setCreateTicketState("creating");

    const meterRead = {
      date_of_read: moment(meterReadValues.date).format("YYYY-MM-DD"),
      read_value: meterReadValues.read,
      supply_id: supplyData.supply_id,
      account_id: supplyData.account_id,
      account_name: supplyData.account_name,
      site_id: supplyData.site_id,
      supply_number: supplyData.supply_number,
    }

    if (instance && account) {
      const accessToken = await getAccessToken(instance, account);
      const response = await fetch(`${import.meta.env.VITE_API_URL}/tickets/meterRead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(meterRead)
      });
      const responseJson = await response.json();

      setCreateTicketState("done")
      return responseJson;

      // !!! should maybe add in error (try/catch?) on this and check all other calls
    }
    setCreateTicketState("") // !!! set status if failed ticket creation
  }

  if (createTicketState) return (createTicketState==="creating") ? <><Spinner /></> : <>Ticket is Created!</>

  return (
    <Popover isOpen={isOpen} initialFocusRef={firstFieldRef} onOpen={onOpen} onClose={onClose} closeOnBlur={false}>
      <PopoverTrigger>
        <Link><Icon as={FaTachometerAlt}/> Submit Meter Read</Link>
      </PopoverTrigger>
      <PopoverContent p={3}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <FormControl isRequired>
              <VStack>
                <Box>
                  <FormLabel>Date of Read</FormLabel>
                  <DatePicker className="custom-datepicker" selected={meterReadValues.date} maxDate={moment().toDate()} dateFormat="dd/MM/yyyy" onChange={(dateChosen) => setMeterReadValues({...meterReadValues, date: dateChosen})} />
                </Box>
                <Box>
                  <FormLabel>Value</FormLabel>
                  <NumberInput ref={firstFieldRef} min={0} value={meterReadValues.read} onChange={(val) => setMeterReadValues({...meterReadValues, read: parseInt(val)})}>
                    <NumberInputField  />
                  </NumberInput>
                </Box>
              </VStack>
              <ButtonGroup display='flex' justifyContent='flex-end' p={3}>
                <Button variant='outline' onClick={onClose}>Cancel</Button>
                <Button colorScheme='teal' onClick={handleFormSubmit} isDisabled={submitButtonDisabled}>Submit</Button>
              </ButtonGroup>
            </FormControl>
          </PopoverBody>
        </FocusLock>
      </PopoverContent>
    </Popover>
  )
}

export default MeterReadSubmission