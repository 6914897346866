import { Icon } from "@chakra-ui/react";
import { FaBoltLightning, FaFire, FaDroplet, FaQuestion } from 'react-icons/fa6';

type SupplyModalProps = {
  utility: string
}

function SupplyIcon({utility}: SupplyModalProps) {

  // returns nothing if empty
  if (utility == "") return

  let icon;
  switch(utility){
    case "Electricity":
      icon = FaBoltLightning;
      break;
    case "Gas":
      icon = FaFire;
      break;
    case "Water":
      icon = FaDroplet;
      break;
    default:
      icon = FaQuestion;
  }

  return (
    <Icon as={icon}/>
  )
}

export default SupplyIcon