import { GridItem, Grid, Box, HStack, Icon, Text, Flex, ResponsiveValue } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "wouter";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

type HomeTemplateProps = {
    children: ReactNode;
};

type ModuleProps = {
    children: ReactNode;
    title: string;
    link?: string;
    icon?: ReactNode;
    size?: "small" | "medium" | "large";
    height?: ResponsiveValue<number | "auto">;
    colSpan?: ResponsiveValue<number | "auto">;
    rowSpan?: ResponsiveValue<number | "auto">;
    order?: ResponsiveValue<number | "inherit">;
};

function HomeTemplate({ children }: HomeTemplateProps) {

    return (
        <Grid  gap={8} padding={4}
            templateColumns={{
                base: "repeat(2, 1fr)", // 2 columns on small screens (mobile)
                md: "repeat(3, 1fr)", // 3 columns on medium screens (tablets)
                lg: "repeat(4, 1fr)", // 4 columns on large screens (desktops)
            }}>
            {children}
        </Grid>
    );
}

function Module({ children, title, link, icon, height, colSpan, rowSpan, order }: ModuleProps) {

    const moduleContent = (
        <Flex direction="column" h="100%">
            <Box px={4} bg="purple.100" color="white" borderTopRadius="12px">
                <HStack justify="space-between">
                    <Box p={1}>
                        <HStack>
                            {icon}
                            <Text fontSize="sm">{title}</Text>
                        </HStack>
                    </Box>
                    {link && <Icon as={FaArrowUpRightFromSquare} />}
                </HStack>
            </Box>
            <Box flex="1">{children}</Box>
        </Flex>
    );

    return (
        <GridItem bg="white" borderRadius="12px" boxShadow="lg" colSpan={colSpan} rowSpan={rowSpan} height={height} order={order}>
            {link ? (
                <Link href={link}>
                    {moduleContent}
                </Link>
            ) : (
                moduleContent
            )}
        </GridItem>
    );
}

HomeTemplate.Module = Module;

export default HomeTemplate;
