import { useContext } from "react";
import { GridContext } from "../contexts/PersistentGridContext";

// Hook to allow other components to consume the context
export const usePeristentGrid = () => {
    const context = useContext(GridContext);
    if (!context) {
        throw new Error("useGrid must be used within a GridProvider");
    }
    return context;
};