import { CustomCellRendererProps } from "ag-grid-react";
import SupplyPopover from "./SupplyPopover";
import RenewalsDrawer from "./RenewalsDrawer";
import { Icon, Link, useDisclosure } from "@chakra-ui/react";
import { FaRegCalendar } from "react-icons/fa6";
import SupplyDrawer from "./SupplyDrawer";

function SupplyLinkRenderer(params: CustomCellRendererProps) {
  if (!params.data) return
  const { isOpen, onOpen, onClose } = useDisclosure(); // tracks status of renewals drawer
  // !!! some views have supply_verbose and others have supply_name (supplies vs tickets views)
  const supplyName = params.data.supply_verbose || params.data.supply_name;

  return (
    <>
      <SupplyDrawer id={params.data.supply_id} isOpen={isOpen} onClose={onClose} />
      <SupplyPopover supplyId={params.data.supply_id} supplyName={supplyName} onOpen={onOpen}></SupplyPopover>
    </>  
  );
}

function CEDLinkRenderer(params: CustomCellRendererProps) {
  if (!params.data) return
  const { isOpen, onOpen, onClose } = useDisclosure(); // tracks status of renewals drawer

  const date = (params.data.contract_end_date) ? new Date(params.data.contract_end_date) : null;
  let dateString = getCEDString(date, params.data.rollingcontract);

  return (
    <>
      <RenewalsDrawer isOpen={isOpen} onClose={onClose} ced={date} />
      <Link color='blue.100' onClick={onOpen}><Icon as={FaRegCalendar}/> {(dateString)}</Link>
    </>
  )
}

// function that returns text representation of CED including unknown / out of contract - !!! move this to a util file?
function getCEDString(ced: Date | null, rolling: boolean){
  let dateString;
  if (rolling){
    dateString = "Out of Contract";
  } else {
    dateString = (ced) ? ced.toLocaleDateString() : "Unknown";
  }
  return dateString;
}

export { SupplyLinkRenderer, CEDLinkRenderer, getCEDString }