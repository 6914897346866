import { useMemo, useRef, useState } from "react";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { SupplyType } from "../../types"
import { Box } from "@chakra-ui/react";
import moment from "moment";
import { CEDLinkRenderer, SupplyLinkRenderer } from "../../components/CellRenderers";

type EventCalendarProps = {
  suppliesData: SupplyType[] | undefined
  selectedDates: Date[]
}

function CEDGrid({suppliesData, selectedDates}: EventCalendarProps) {

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef<SupplyType>[]>([
    { field: "account_name", headerName: "Account Name", sort: "asc", sortIndex: 0, minWidth: 150 },
    { field: "site_group", headerName: "Site Group", sort: "asc", hide: true, sortIndex: 1, minWidth: 150 },
    { field: "site_address", headerName: "Site Address", sort: "asc", sortIndex: 2 },
    { field: "supply_type", headerName: "Supply Type", sort: "asc", sortIndex: 3, width: 110 },
    { field: "supply_subtype", headerName: "Supply Subtype", sort: "asc", hide: true, sortIndex: 4 },
    { field: "supply_verbose", headerName: "Supply Number", sort: "asc", sortIndex: 5, cellRenderer: SupplyLinkRenderer, minWidth: 300, cellStyle: {overflow: "visible"} },  
    { field: "supply_supplier", headerName: "Supplier" },
    { field: "contract_end_date", headerName: "Contract End Date", rowGroup: true, hide: true, sort: "asc", cellRenderer: CEDLinkRenderer },
    { field: "eb_currentconsumption", headerName: "Current Consumption", hide: true },
    { field: "supply_number", headerName: "Supply Number Short", hide: true },
    { field: "ooc", headerName: "OOC", hide: true  }, 
    { field: "eb_meterserialnumber", headerName: "Meter Serial Number", hide: true  },
  ]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      filter: true,
      enableRowGroup: true,
      enableValue: true,
    }),
    []
  );

  async function setFilterModelRefresh(gridRef: React.RefObject<AgGridReact<any>>, selectedDates: Date[]){
    const selectedDatesFilter = (selectedDates.length > 0) ? selectedDates.map((date: Date) => moment(date).format("YYYY-MM-DD")) : null;
    await gridRef.current?.api.setColumnFilterModel('contract_end_date', { values: selectedDatesFilter });
    gridRef.current?.api.onFilterChanged(); // ag grid needs to force filter refresh
  }

  //filter by selected dates in calendar
  const gridRef = useRef<AgGridReact>(null);
  setFilterModelRefresh(gridRef, selectedDates) // need to use async function to await setColumnFilterModel on first load

  return(
    <Box w="100%" h="100%">
      <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: '400px', width: '100%' }} // the Data Grid will fill the size of the parent container
      >
      <AgGridReact<SupplyType>
          rowData={suppliesData}
          columnDefs={colDefs}
          groupDisplayType={'groupRows'}
          rowGroupPanelShow={"always"}
          defaultColDef={defaultColDef}
          autoSizeStrategy={{type: 'fitGridWidth'}}
          ref={gridRef}
          groupDefaultExpanded={(selectedDates.length>0)?1:0} // expand groups after dates selected but minimise when not
          //domLayout={"autoHeight"} // !!! this can cause performance issues for large dataset - remove when we have finalised layout and can set fixed height
      />
    </div>
  </Box>
  )

}

export default CEDGrid