import { GridState } from 'ag-grid-community';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import { SavedView } from '../types';

// Define a type for the context value
type GridContextType = {
    reset: () => void;
    updateGridState: (newState: GridState) => void;
    gridState: GridState | undefined;
    resetKey: string;
    views?: SavedView[];
    setView: (viewName: string) => void;
    selectedView: SavedView | null;
};

type GridProviderProps = {
    children: ReactNode;
    gridKey: string;
    views?: SavedView[];
}

// Create the context with a default value
export const GridContext = createContext<GridContextType | undefined>(undefined);

// GridProvider that encapsulates the state and provides it to children components
export const PersistentGridProvider = ({ children, gridKey, views }: GridProviderProps) => {
    const [gridState, setGridState] = useState<GridState | undefined>(undefined);
    const [resetKey, setResetKey] = useState(uuidv4());
    const [selectedView, setSelectedView] = useState<SavedView | null>(null);

    useEffect(() => {
        if (!gridKey)
            return;

        loadStateFromStorage();
        setResetKey(uuidv4());
    }, [gridKey]);

    const reset = () => {
        if (selectedView) {
            setGridState(selectedView.state);
        } else {
            setGridState(undefined);
            localStorage.removeItem(`${gridKey}_gridState`);
        }
        
        setResetKey(uuidv4());
    };

    function loadStateFromStorage() {
        const savedState = localStorage.getItem(`${gridKey}_gridState`);
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            setGridState(parsedState);
        }
    }

    function updateGridState(newState: GridState) {
        if (selectedView)
            return;

        console.log(newState)
        localStorage.setItem(`${gridKey}_gridState`, JSON.stringify(newState));
    }

    function setView(viewName: string) {
        if (!views)
            return

        const view = views.find(v => v.name === viewName) || null;
        setSelectedView(view);

        if (view) {
            setGridState(view.state);
        } else {
            loadStateFromStorage();
        }
        
        setResetKey(uuidv4());
    }

    return (
        <GridContext.Provider value={{ views, setView, selectedView, reset, updateGridState, gridState, resetKey }}>
            {children}
        </GridContext.Provider>
    );
};
