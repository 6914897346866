import { ChartDisplay, DynamicChartData, HHData, HHDataVector } from "../types";
import * as Enums from "./Enums";

export function transformVectorData(data: HHDataVector[]): HHData[] {
    const transformedData: HHData[] = [];
    
    data.forEach((item: HHDataVector) => {
        const readDate = new Date(item.read_date);
        for (let i = 0; i < 48; i++) {
            const minutesToAdd = i * 30;
            const periodDate = new Date(readDate);
            periodDate.setMinutes(readDate.getMinutes() + minutesToAdd);
            const hhKey = `HH${String(i + 1).padStart(2, '0')}`;

            const transformedDataPoint: HHData =  {
                period_end: periodDate,
                kwh: item[hhKey] as number,
                site_group: item.site_group,
                account_name: item.account_name,
                supply_type: item.supply_type,
                account_id: item.account_id,
                granularity: Enums.PeriodEnum.HOURLY,
                supply_number: item.supply_number,
                site_address: item.site_address,
                site_id: item.site_id,
                supply_id: item.supply_id,
                supply_subtype: item.supply_subtype,
            };

            transformedData.push(transformedDataPoint);
        }
    });

    return transformedData;
}

export function formatForRecharts(data: HHData[], chartDisplay: ChartDisplay): { chartData: DynamicChartData[], uniqueGroups: string[] } {
    const groupedData = new Map<string, Map<string, number>>();
    const groupsSet = new Set<string>(); // To keep track of all unique groups

    let totalOverallConsumption = 0;

    data.forEach((dataPoint) => {
        if(chartDisplay?.supplies.includes(dataPoint.supply_number)) {
            let group: string | undefined;
            switch (chartDisplay?.grouping) {
                case Enums.GroupEnum.ACCOUNT:
                    group = dataPoint.account_name;
                    break;
                case Enums.GroupEnum.SITE_GROUP:
                    group = dataPoint.site_group;
                    break;
                case Enums.GroupEnum.SUPPLY_TYPE:
                    group = dataPoint.supply_type;
                    break;
                case Enums.GroupEnum.TOTAL:
                    group = "Total";
                    break;
                default:
                    break;
            }

            if (group && dataPoint.kwh) {
                totalOverallConsumption += dataPoint.kwh;

                const period = new Date(dataPoint.period_end).toISOString(); // Use ISO string for consistent key format
    
                if (!groupedData.has(period)) {
                    groupedData.set(period, new Map<string, number>());
                }
    
                const groupMap = groupedData.get(period)!;
                if (groupMap.has(group)) {
                    groupMap.set(group, groupMap.get(group)! + dataPoint.kwh);
                } else {
                    groupMap.set(group, dataPoint.kwh);
                }
    
                // Track the group
                groupsSet.add(group);
            }
        }
    });

    const overallCount = groupedData.size;
    const overallAverage = totalOverallConsumption / overallCount;

    const chartData: DynamicChartData[] = [];

    groupedData.forEach((groupMap, period) => {
        const dataPoint: DynamicChartData = { date: new Date(period) };

        // Initialize all groups with 0 consumption
        groupsSet.forEach((group) => {
            dataPoint[group] = 0;
        });

        // Populate with actual consumption data
        groupMap.forEach((consumption, group) => {
            dataPoint[group] = consumption;
        });

        // Calculate the average consumption
        const totalConsumption = Array.from(groupMap.values()).reduce((sum, val) => sum + val, 0);
        const averageConsumption = totalConsumption / groupMap.size;
        dataPoint.trend = averageConsumption;

        dataPoint.overallAverage = overallAverage;

        chartData.push(dataPoint);
    });

    chartData.sort((a, b) => a.date.getTime() - b.date.getTime());

    const uniqueGroups = Array.from(groupsSet);

    return {chartData, uniqueGroups};
}