import { useContext } from "react";
import { SelectedAccountContext } from "../contexts/SelectedAccountContext";

export const useSelectedAccount = () => {
    const context = useContext(SelectedAccountContext);
    if (!context) {
        throw new Error("useSelectedAccount must be used within an AccountProvider");
    }
    return context;
};
