import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../auth/authConfig";

export function useAuth() {
    const { instance } = useMsal();

    function logout() {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    }

    function passwordReset() {
        instance.loginRedirect({
            authority: b2cPolicies.authorities.forgotPassword.authority, 
            scopes: [
                "https://eyesource.onmicrosoft.com/98ee402c-d9a5-481e-b9ce-1e646b830c45/CustomerPortal.User",
            ]
        });
    }

    return {logout, passwordReset};
}