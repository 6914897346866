import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, Select, Textarea, Text, Box, Spinner } from "@chakra-ui/react"
import { useState, useEffect, useContext } from "react";
import { NotificationContextType, SubmitTicketType, SupplyType } from "../../types";
import { NotificationContext } from "../../components/NotificationPane";
import { useApi } from "../../hooks/useApi";
import { useForm  } from 'react-hook-form'
import { useAccount, useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../auth/authFunctions";
import { NIL } from "uuid";

const categoryList = [
  {category_name: "Change of Tenancy"},
  {category_name: "Supplier Query"},
]

// !!! would be best to get this list from CRM
const supplierList = [
  {supplier_id: "bf28b569-2450-e711-810e-70106fa7b0f1", supplier_name: "British Gas"},
  {supplier_id: "d928b569-2450-e711-810e-70106fa7b0f1", supplier_name: "EDF"},
  {supplier_id: "eb28b569-2450-e711-810e-70106fa7b0f1", supplier_name: "Npower"},
  {supplier_id: "0329b569-2450-e711-810e-70106fa7b0f1", supplier_name: "SSE"},
  {supplier_id: "0529b569-2450-e711-810e-70106fa7b0f1", supplier_name: "Total Gas and Power Ltd"},
]

type TicketsFormProps = {
  prefillSupplyId?: string
}

function TicketsForm({prefillSupplyId}: TicketsFormProps) {

  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {ready, getSupplies, createTicket} = useApi();
  const [suppliesData, setSuppliesData] = useState<SupplyType[] | undefined>(undefined);
  const [ticketSubmitted, setTicketSubmitted] = useState<Boolean>(false)
  const [filesToStore, setFilesToStore] = useState<null | File[]>(null)

  async function loadSupplies() {
      try {
        const responseJson = await getSupplies();
        setSuppliesData(responseJson);
        //prefill supply if exists
        if (prefillSupplyId) setValue("supply_id", prefillSupplyId);
        return
      } catch(err) {
        pushNotification("Could not load data.")
        return 
      }
  }

  // upload files to the created ticket
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  async function uploadFiles(newDir: string){
    if (!filesToStore || filesToStore.length < 1) return;
    if (instance && account) {
      const accessToken = await getAccessToken(instance, account);
      const body = { newDir }
      const response = await fetch(`${import.meta.env.VITE_API_URL}/files/createTicketDir`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify(body),
      });

      
      console.log(filesToStore)
      filesToStore.forEach((file) => {
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onloadend = () => {
          uploadFile(file.name, reader.result as ArrayBuffer, newDir);
        }
        reader.readAsArrayBuffer(file)
      })

      const responseJson = await response.json();
      console.log(responseJson)
    }
  }

  async function uploadFile(fileName: string, fileContent: ArrayBuffer, dir: string){
    if (instance && account) {
      const accessToken = await getAccessToken(instance, account);
      console.log(fileContent)
      const byteArray = Array.from(new Uint8Array(fileContent));
      const body = { fileName, byteArray, dir }
      const response = await fetch(`${import.meta.env.VITE_API_URL}/files/uploadTicketFile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify(body),
      });
      const responseJson = await response.json();
      console.log(responseJson)
    }
  }

  const { handleSubmit, register, setValue, watch, reset, formState: { errors, isSubmitting } } = useForm<SubmitTicketType>()
  const subcategory = watch("subcategory");
  const accountid = watch("account_id");
  const site = watch("site_id");
  const supply = watch("supply_id");

  // updates site and account when a supply is selected
  useEffect(() => {
    if (supply === "") return
    const supplyData = suppliesData?.find((a) => a.supply_id === supply)
    if (supplyData){
      setValue("account_id", supplyData.account_id);
      setValue("site_id", supplyData.site_id);
    } 
  }, [supply])

  // updates account when a site is selected
  useEffect(() => {
    if (site === "") return
    const supplyData = suppliesData?.find((a) => a.site_id === site)
    if (supplyData){
      setValue("account_id", supplyData.account_id);
    } 
  }, [site])

  useEffect(() => {
    if (ready){
      loadSupplies();
    } 
  }, [ready]);

  async function onSubmit(values: SubmitTicketType){
    if (ready) {
      try {
        await createTicket(values);
        if (filesToStore && filesToStore.length > 0) await uploadFiles("TICKET ID "+Math.floor(Math.random() * 999999));
        setTicketSubmitted(true);
        reset(); // resets react hook form
      } catch (err) {
        pushNotification("Could not create ticket.", "error")
      }
    }
  }

  function handleFilesChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return setFilesToStore(null);
    const files = Array.from(e.target.files);
    setFilesToStore(files);
  }
  // console.log(filesToStore[0])

  if (suppliesData === undefined) return <Spinner />

  // gets list of unique accounts - filter removes duplicate
  const accountList = suppliesData
    .filter((obj1, i, arr) => 
      arr.findIndex(obj2 => (obj2.account_id === obj1.account_id)) === i
    )
    .map(a => ({account_name: a.account_name, account_id: a.account_id}));

  // gets list of unique sites - filter removes duplicate - filters based off account value selected
  let siteList = suppliesData
    .filter((obj1, i, arr) => 
      arr.findIndex(obj2 => (obj2.site_id === obj1.site_id)) === i
    )
    .map(a => ({site_name: a.site_name, site_id: a.site_id, account_id: a.account_id}));
  if ((accountid)) siteList = siteList.filter((supply) => supply.account_id === accountid);

  // gets list of unique supplies - filter removes duplicate - filters based off account and site values selected
  let supplyList = suppliesData.map(a => ({supply_verbose: a.supply_verbose, supply_id: a.supply_id, site_id: a.site_id, account_id: a.account_id}));
  if (accountid) supplyList = supplyList.filter((supply) => supply.account_id === accountid);
  if (site) supplyList = supplyList.filter((supply) => supply.site_id === site);

  if (ticketSubmitted) return (
    <>
      <Heading color={"green"}>Ticket submitted successfully.</Heading>
      <Button onClick={() => setTicketSubmitted(false)}>Submit another ticket</Button>
    </>
  )
  return (
    <>
    <Text borderTopRadius={"12px"} px={4} color={"white"} backgroundColor={"#B391C3"} fontSize='lg'>New Ticket</Text>
    <Box p={4}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={errors.subcategory ? true : undefined} isRequired>
        <FormLabel htmlFor='subcategory'>Category</FormLabel>
        <Select placeholder='Select...' id="subcategory" {...register('subcategory', {required: true})}>
          {categoryList.map((category) => {
            return <option key={category.category_name} value={category.category_name}>{category.category_name}</option>
          })}
        </Select>
        <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.subject ? true : undefined} isRequired>
        <FormLabel htmlFor='subject'>Subject</FormLabel>
        <Input id='subject' {...register('subject', {required: true, maxLength: { value: 50, message: 'Maximum length is 50' }, })} />
        <FormHelperText>Max 50 Characters.</FormHelperText>
        <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.account_id ? true : undefined}>
        <FormLabel htmlFor='account_id'>Related Account</FormLabel>
        <Select id="account_id" {...register('account_id')}>
          <option value={NIL} selected>Select...</option>
          {accountList.map((account) => {
            if (account.account_id === null) return
            return <option key={account.account_id} value={account.account_id}>{account.account_name}</option>
          })}
        </Select>
        <FormErrorMessage>
          {errors.account_id && errors.account_id.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.site_id ? true : undefined}>
        <FormLabel htmlFor='site_id'>Related Site</FormLabel>
        <Select id="site_id" {...register('site_id')}>
          <option value={NIL} selected>Select...</option>
          {siteList.map((site) => {
            if (site.site_id === null) return
            return <option key={site.site_id} value={site.site_id}>{site.site_name as string}</option>
          })}
        </Select>
        <FormErrorMessage>
          {errors.site_id && errors.site_id.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.supply_id ? true : undefined}>
        <FormLabel htmlFor='supply_id'>Related Supply</FormLabel>
        <Select id="supply_id" {...register('supply_id')}>
          <option value={NIL} selected>Select...</option>
          {supplyList.map((supply) => {
            if (supply.supply_id === null) return
            return <option key={supply.supply_id} value={supply.supply_id}>{supply.supply_verbose}</option>
          })}
        </Select>
        <FormErrorMessage>
          {errors.supply_id && errors.supply_id.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.supplier_id ? true : undefined} isRequired={subcategory==="Supplier Query"}>
        <FormLabel htmlFor='supplier_id'>Related Supplier</FormLabel>
        <Select id="supplier_id" {...register('supplier_id', {required: (subcategory==="Supplier Query")})} >
          <option value={NIL} selected>Select...</option>
          {supplierList.map((supplier) => {
            return <option key={supplier.supplier_id} value={supplier.supplier_id}>{supplier.supplier_name}</option>
          })}
        </Select>
        <FormErrorMessage>
          {errors.supplier_id && errors.supplier_id.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.description ? true : undefined} isRequired>
        <FormLabel htmlFor='description'>Description</FormLabel>
        <Textarea id='description' {...register('description', {required: true})} />
        <FormErrorMessage>
          {errors.description && errors.description.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor='files'>Files</FormLabel>
        <Input placeholder='File upload' size='md' type='file' multiple onChange={handleFilesChange} />
      </FormControl>

      <Button mt={4} colorScheme='teal' isLoading={isSubmitting} type='submit'>
        Submit
      </Button>

    </form>
    </Box>
    </>
  )
}

export default TicketsForm