import { Select } from "@chakra-ui/react";
import { usePeristentGrid } from "../../hooks/usePersistentGrid";

export default function PersistentGridViewSelector() {
    const { views, setView, selectedView } = usePeristentGrid();

    return (
        <Select 
            
            placeholder='Preset Views'
            value={selectedView ? selectedView.name : ""}
            onChange={(e) => setView(e.target.value)} 
        >
            {views?.map((view) => (
                <option key={view.name} value={view.name}>
                    {view.name}
                </option>
            ))}
        </Select>
    );
}