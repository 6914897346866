// AddUserModal.js
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Switch,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { NotificationContextType, UserType } from "../../types";
import { useApi } from '../../hooks/useApi';
import { NotificationContext } from '../../components/NotificationPane';

type UpdateUserModalProps = {
    isOpen: boolean;
    onClose: () => void;
    reloadUsers: () => void;
    user: UserType | null;
};

function UpdateUserModal({ isOpen, onClose, user, reloadUsers } : UpdateUserModalProps) {
  const { updateUser, deleteUser, ready } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [updatedUser, setUpdatedUser] = useState<UserType | null>(null);
  const [buttonDisabled, setbuttonDisabled] = useState(false);

  useEffect(() => {
    if (user)
      setUpdatedUser({...user});
  }, [user]);

  async function handleUpdateUser() {
    if (!updatedUser || !ready) {
      pushNotification("Could not update user.", "error");
      return;
    }

    setbuttonDisabled(true);

    try {
      await updateUser(updatedUser);
      pushNotification("User updated.", "success");
      reloadUsers();
    } catch {
      pushNotification("Could not update user.", "error");
    }

    setbuttonDisabled(false);
    onClose();
  }

  async function handleDeleteUser() {
    if (!updatedUser || !ready) {
      pushNotification("Could not delete user.", "error");
      return;
    }

    setbuttonDisabled(true);

    try {
      await deleteUser(updatedUser);
      pushNotification("User deleted.", "success");
      reloadUsers();
    } catch {
      pushNotification("Could not delete user.", "error");
    }

    setbuttonDisabled(false);
    onClose();
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                value={updatedUser?.firstname}
                onChange={(e) => {
                  if (updatedUser)
                    setUpdatedUser({ ...updatedUser, firstname: e.target.value })
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={updatedUser?.lastname}
                onChange={(e) => {
                  if (updatedUser)
                    setUpdatedUser({ ...updatedUser, lastname: e.target.value })
                }}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel htmlFor="admin" mb="0">
                Admin
              </FormLabel>
              <Switch
                colorScheme='green'
                id="admin"
                isChecked={updatedUser?.customeradmin}
                onChange={(e) => {
                  if (updatedUser)
                    setUpdatedUser({ ...updatedUser, customeradmin: e.target.checked })
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent='space-between' width='100%'>
              <Button colorScheme='red' onClick={handleDeleteUser} isLoading={buttonDisabled || !ready} isDisabled={buttonDisabled || !ready}>Delete</Button>
              <HStack spacing='1'>
                <Button backgroundColor='#99CD81' _hover={{ bg: '#6B8F5A' }} color={"white"} colorScheme='blue' mr={3} onClick={handleUpdateUser} isLoading={buttonDisabled || !ready} isDisabled={buttonDisabled || !ready}>
                  Save
                </Button>
                <Button backgroundColor='#6A7D80' _hover={{ bg: '#4A5759' }} color={"white"} variant='ghost' onClick={onClose}>Close</Button>
              </HStack>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateUserModal;
