import { Box, Grid, GridItem, Skeleton, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { useApi } from "../../hooks/useApi";
import { useEffect, useState } from "react";
import { AccountSummary as AccountSummaryType } from "../../types";

function AccountSummary() {
  const { ready, getAccountSummary } = useApi();
  const [accountSummary, setAccountSummary] = useState<AccountSummaryType | null>(null);

  useEffect(() => {
    async function loadSummary() {
      setAccountSummary(null);
      try {
        const summary = await getAccountSummary();
        if (summary.length > 0) setAccountSummary(summary[0]);
      } catch (err) {
        console.error(err);
      }
    }

    if (ready) loadSummary();
  }, [ready, getAccountSummary]);

  return (
    <Box h={'300'} className="custom-scroll" overflowY="scroll">
      <Grid templateColumns='repeat(1, 1fr)' gap={4} padding={2} margin={2}>
        <GridItem>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel>Sites</StatLabel>
              <StatNumber>{accountSummary ? accountSummary.site_count : <Skeleton height={"36px"}/>}</StatNumber>
            </Stat>
          </Box>
        </GridItem>
        <GridItem>
        <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
          <Stat m={2}>
            <StatLabel>Electricity Sites</StatLabel>
            <StatNumber>{accountSummary ? accountSummary.site_count_e : <Skeleton height={"36px"}/>}</StatNumber>
          </Stat>
          </Box>
        </GridItem>
        <GridItem>
        <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
          <Stat m={2}>
            <StatLabel>Gas Sites</StatLabel>
            <StatNumber>{accountSummary ? accountSummary.site_count_g : <Skeleton height={"36px"}/>}</StatNumber>
          </Stat>
          </Box>
        </GridItem>
        <GridItem>
        <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
          <Stat m={2}>
            <StatLabel>Total Consumption</StatLabel>
            <StatNumber>{accountSummary ? accountSummary.consumption_total : <Skeleton height={"36px"}/>}</StatNumber>
          </Stat>
          </Box>
        </GridItem>
        <GridItem>
        <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
          <Stat m={2}>
            <StatLabel>Electricity Consumption</StatLabel>
            <StatNumber>{accountSummary ? accountSummary.consumption_total_e : <Skeleton height={"36px"}/>}</StatNumber>
          </Stat>
          </Box>
        </GridItem>
        <GridItem>
        <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
          <Stat m={2}>
            <StatLabel>Gas Consumption</StatLabel>
            <StatNumber>{accountSummary ? accountSummary.consumption_total_g : <Skeleton height={"36px"}/>}</StatNumber>
          </Stat>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default AccountSummary;
