import { ReactNode } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import eyebrightFlower from '../assets/eyebright-flower.png';
import { useCurrentUser } from '../contexts/CurrentUserContext';

type AdminRouteProps = {
  children: ReactNode;
}

const AdminRoute = ({ children }: AdminRouteProps) => {
  const { isAdmin, isCustomerAdmin } = useCurrentUser();

  if (!isAdmin && !isCustomerAdmin) {
    return (
      <Flex
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box p={5} shadow="md" borderWidth="1px">
          <VStack>
            <img src={eyebrightFlower} width="100"/>
            <Text>Access denied</Text>
          </VStack>
        </Box>
      </Flex>
    );
  }

  return <>{children}</>;
};

export default AdminRoute;
