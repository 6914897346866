import { createContext, useState, ReactNode, useContext, useEffect } from "react";
import { Account, NotificationContextType } from "../types";
import { useApi } from "../hooks/useApi";
import { useSearch } from "wouter";
import { NotificationContext } from "../components/NotificationPane";

interface SelectedAccountContextType {
    accounts: Account[] | null;
    selectedAccount: Account | null;
    setSelectedAccount: (account: Account) => void;
}

export const SelectedAccountContext = createContext<SelectedAccountContextType | undefined>(undefined);

export const SelectedAccountProvider = ({ children }: { children: ReactNode }) => {
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const { ready, getAccounts } = useApi();
    const [accounts, setAccounts] = useState<Account[] | null>(null);
    const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
    const search = useSearch();

    useEffect(() => {
        if (accounts) {
            const params = new URLSearchParams(search);
            const accountId = params.get("accountid");

            if (accountId) {
                const account = accounts.find((account) => account.account_id === accountId);
                if (account) {
                    setSelectedAccount(account);
                }
            } else {
                setSelectedAccount(accounts[0]);  // Set initial accountId
            }
        }
    }, [accounts]);

    useEffect(() => {
        async function loadAccounts() {
            setSelectedAccount(null);
            setAccounts(null);
            try {
                const accounts = await getAccounts();
                if (accounts.length > 0) {
                    setAccounts(accounts);
                }
            } catch (err) {
                pushNotification("Could not load Account.", "error");
            }
        }

        if (ready) {
            loadAccounts();
        }
    }, [ready, getAccounts]);

    return (
        <SelectedAccountContext.Provider value={{ accounts, selectedAccount, setSelectedAccount }}>
            {children}
        </SelectedAccountContext.Provider>
    );
};
