import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "../styles/grid-styles.css";
import { SelectedAccountProvider } from "../contexts/SelectedAccountContext";
import BaseTemplate from "../components/BaseTemplate";
import AccountSelector from "../components/AccountSelector";
import FilesComponent from "./Files/FilesComponent";

function Files() {
  return (
    <SelectedAccountProvider>
      <BaseTemplate  fitToWindow={true}>
        <BaseTemplate.Header>
          <AccountSelector />
        </BaseTemplate.Header>
        <BaseTemplate.Body>
          <FilesComponent/>
        </BaseTemplate.Body>
      </BaseTemplate>
    </SelectedAccountProvider>
  )
}

export default Files