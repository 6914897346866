import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { UserType } from "../types";
import { useApi } from "../hooks/useApi";
import { Spinner } from "@chakra-ui/react";

const CurrentUserContext = createContext<CurrentUserContextType>({
    user: null,
    isAdmin: false,
    isCustomerAdmin: false,
});

interface CurrentUserContextType {
    user: UserType | null;
    isAdmin: boolean;
    isCustomerAdmin: boolean,
}

interface CurrentUserProviderProps {
    children: ReactNode;
}

export function CurrentUserProvider({ children } : CurrentUserProviderProps) {
    const {getCurrentUser, ready: apiReady} = useApi();
    const [user, setUser] = useState<UserType | null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCustomerAdmin, setIsCustomerAdmin] = useState(false);
    const [ready, setReady] = useState(false);  

    useEffect(() => {
        async function fetchUser() {
            try {
                const userData = await getCurrentUser();
                setUser(userData);
                setIsAdmin(!!Number(userData.admin));
                setIsCustomerAdmin(!!(Number(userData.customeradmin)));
            } catch (err) {
                console.error(err);
            } finally {
                setReady(true);
            }
        }

        if (apiReady)
            fetchUser();
    }, [apiReady]);

    if (!ready) {
        return (
            <Spinner size="xl" />
        );
    }

    return (
        <CurrentUserContext.Provider value={{ user, isAdmin, isCustomerAdmin }}>
            {children}
        </CurrentUserContext.Provider>
    );
}

export function useCurrentUser() {
    return useContext(CurrentUserContext);
}
