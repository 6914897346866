import { Select, Center, Skeleton } from "@chakra-ui/react";
import { useSelectedAccount } from "../hooks/useSelectedAccount";


const AccountSelector = () => {
    const { accounts, selectedAccount, setSelectedAccount } = useSelectedAccount();

    return (
        <>
            {accounts ? (
                <Select 
                    value={selectedAccount?.account_id} 
                    onChange={(e) => setSelectedAccount(accounts.filter((account) => account.account_id === e.target.value)[0])} 
                >
                    {accounts.map((account) => (
                        <option key={account.account_id} value={account.account_id}>
                            {account.Account_name}
                        </option>
                    ))}
                </Select>
            ) : (
                <Center>
                    <Skeleton height={"36px"} width={"100%"}/>
                </Center>
            )}
        </>
    );
};

export default AccountSelector;
