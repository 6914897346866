import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { HHData, Filters, DynamicChartData, HHDataVector, ChartDisplay, NotificationContextType } from "../../types";
import SelectionPane from "./SelectionPane";
import { AbsoluteCenter, Box, Spinner } from "@chakra-ui/react";
import * as Enums from "../../util/Enums";
import AgFilterPane from "./AgFilterPane";
import { UpDownIcon } from "@chakra-ui/icons";

import GraphArea from "./GraphArea";
import { useApi } from "../../hooks/useApi";
import { defaultChartDisplay, defaultFilters } from "../../util/DefaultFilters";
import { formatForRecharts, transformVectorData } from "../../util/DataTransformations";
import { NotificationContext } from "../../components/NotificationPane";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import BaseTemplate from "../../components/BaseTemplate";

export default function HHDashboard() {
    const chartRef = useRef(null);
    const [filters, setFilters] = useState<Filters | null>(null); 
    const {ready, getHHData} = useApi();
    const [chartDisplay, setChartDisplay] = useState<ChartDisplay | null>();
    const [rawData, setRawData] = useState<HHData[] | HHDataVector[]>([]);
    const [groups, setGroups] = useState<string[]>([]);
    const [loadingConsumptions, setLoadingConsumptions] = useState<boolean>(true);
    const [chartData, setChartData] = useState<DynamicChartData[]>([])
    const [gridHeight, setGridHeight] = useState<number>();
    const [chartHeight, setChartHeight] = useState<number>();
    const chartGridAreaRef = useRef<HTMLDivElement>(null);
    const dividerRef = useRef<HTMLDivElement>(null);
    const [toggleFocus, setToggleFocus] = useState<boolean>(true);
    const [initialised, setInitialised] = useState<boolean>(false);
    const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

    const getConsumptions = useCallback(async () => {
        if (ready && filters) {
            setLoadingConsumptions(true);

            try {
                const responseJson = await getHHData(filters);
                setRawData(responseJson);
            }
            catch(err) {
                pushNotification("Could not load data.", "error");
            }
            
            setLoadingConsumptions(false);
        }
    }, [filters, getHHData, ready])

    const updateQuery = (): void => {
        getConsumptions();
    }

    useEffect(() => {
        setChartDisplay(defaultChartDisplay)
        setFilters(defaultFilters);
    }, []);

    useEffect(() => {
        //Load the data once filters are ready but use button in future
        if (!initialised && filters) {
            setInitialised(true);
            getConsumptions();
        }
    }, [filters, initialised, getConsumptions]);

    useEffect(() => {
        const startTime = performance.now()

        if (!chartDisplay)
            return

        if (!rawData || !Array.isArray(rawData) || rawData.length === 0) {
            setChartData([]);
            return;
        }

        let data: HHData[] = [];

        if (rawData[0]["HH01"] !== undefined) {
            data = transformVectorData(rawData as HHDataVector[]);
        }
        else {
            data = rawData as HHData[];
        } 

        const formattedData = formatForRecharts(data, chartDisplay);
        setChartData(formattedData.chartData);
        setGroups(formattedData.uniqueGroups);

        const endTime = performance.now()
        console.log(`Data manipulations took ${endTime - startTime} milliseconds`)
    }, [chartDisplay, rawData]);

    useEffect(() => {
        if (chartGridAreaRef?.current && dividerRef?.current) {
            const selRef : HTMLDivElement = chartGridAreaRef.current;
            const divRef : HTMLDivElement = dividerRef.current;
            const areaHeight = (window.innerHeight - 16) - selRef.offsetTop - divRef.offsetHeight
            setChartHeight(areaHeight * (toggleFocus ? 0.6 : 1))
            setGridHeight(!toggleFocus ? 0 : (areaHeight * 0.4))
        }
        
    }, [chartGridAreaRef, toggleFocus, chartData]);

    function onPeriodChange(period: Enums.PeriodEnum) {
        onStartDateChange(null);
        onEndDateChange(null);
        setFilters(prevState => prevState ? { ...prevState, period } : null);
    }

    function onStartDateChange(date: Date | null) {
        setFilters(prevState => prevState ? { ...prevState, startDate: date } : null);
    }

    function onEndDateChange(date: Date | null) {
        setFilters(prevState => prevState ? { ...prevState, endDate: date } : null);
    }

    function onOverlayChange(overlays: Enums.OverlayEnum[]) {
        setChartDisplay(prevState => prevState ? { ...prevState, overlays } : null);
    }

    function onSupplyFilterChange(supplies: string[]) {
        setChartDisplay(prevState => prevState ? { ...prevState, supplies } : null);
    }

    function onChartTypeChange(chartType: Enums.ChartTypeEnum) {
        setChartDisplay(prevState => prevState ? { ...prevState, chartType } : null);
    }

    function onGroupingChange(grouping: Enums.GroupEnum) {
        setChartDisplay(prevState => prevState ? { ...prevState, grouping } : null);
    }

    const handleExport = useCallback(() => {
        if (chartRef.current === null) {
            return;
        }

        htmlToImage.toPng(chartRef.current)
            .then((dataUrl) => {
                download(dataUrl, 'chart.png');
            })
            .catch((err) => {
                console.error('Error exporting chart:', err);
            });
    }, [chartRef]);

    return (
        <BaseTemplate fitToWindow={true}>
            <BaseTemplate.Header>
                <Box>
                    {filters && chartDisplay &&
                        <SelectionPane
                            filters={filters}
                            chartDisplay={chartDisplay}
                            onPeriodChange={onPeriodChange}
                            onOverlayChange={onOverlayChange}
                            onStartDateChange={onStartDateChange}
                            onEndDateChange={onEndDateChange}
                            onChartTypeChange={onChartTypeChange}
                            onGroupingChange={onGroupingChange}
                            updateQuery={updateQuery}
                            handleExport={handleExport}
                        />
                    }
                </Box>
            </BaseTemplate.Header>
            <BaseTemplate.Body>
                <Box ref={chartGridAreaRef}>
                    <Box h={chartHeight}>
                        {(loadingConsumptions || !filters || !chartDisplay) &&
                            <Spinner size='xl' />
                        }
                        {!loadingConsumptions && filters && chartDisplay &&
                            <GraphArea chartRef={chartRef} chartData={chartData} filters={filters} chartDisplay={chartDisplay} groups={groups}/>
                        }
                    </Box>
                    <Box ref={dividerRef} position='relative' height={10}>
                        <AbsoluteCenter bg='white' px='4'>
                            {!toggleFocus &&
                                <UpDownIcon boxSize={4} onClick={() => setToggleFocus(!toggleFocus)}/>
                            }
                            {toggleFocus &&
                                <UpDownIcon boxSize={4} onClick={() => setToggleFocus(!toggleFocus)}/>
                            }
                        </AbsoluteCenter>
                    </Box>
                    <Box h={gridHeight}>
                        { (!filters || !chartDisplay) &&
                            <Spinner size='xl' />
                        }
                        { filters && chartDisplay &&
                            <AgFilterPane 
                                chartDisplay={chartDisplay}
                                onSupplyFilterChange={onSupplyFilterChange}
                            />
                        }
                    </Box>
                </Box>
            </BaseTemplate.Body>
        </BaseTemplate>
    );
}
