import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Icon, Link, Show, useBoolean } from "@chakra-ui/react"
import ContractRenewals from "../pages/ContractRenewals"
import { TbLayoutSidebarRightCollapse, TbLayoutSidebarRightExpand } from "react-icons/tb";

type RenewalsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  ced?: Date | null;
}

function RenewalsDrawer({isOpen, onClose, ced}: RenewalsDrawerProps) {

  const [drawerExpand, setDrawerExpand] = useBoolean(false);
  const drawerExpandIcon = (drawerExpand) ? TbLayoutSidebarRightCollapse : TbLayoutSidebarRightExpand;
  const drawerSize = (drawerExpand) ? "95%" : "50%";

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent maxW={{ base: "90%", lg: drawerSize }}>
        <DrawerCloseButton />
        <DrawerHeader backgroundColor={"purple.100"} color={"white"}>
          <Link onClick={setDrawerExpand.toggle}><Show above='lg'><Icon as={drawerExpandIcon}/></Show></Link> Upcoming Renewals
        </DrawerHeader>
        <DrawerBody backgroundColor={"purple.10"}>
          <ContractRenewals ced={ced} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default RenewalsDrawer
