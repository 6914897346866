// AddUserModal.js
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Switch,
  Select,
} from '@chakra-ui/react';
import { useApi } from '../../hooks/useApi';
import { Account, NotificationContextType } from '../../types';
import { NotificationContext } from '../../components/NotificationPane';

type AddUserModalProps = {
    isOpen: boolean;
    onClose: () => void;
    reloadUsers: () => void;
};

function AddUserModal({ isOpen, onClose, reloadUsers } : AddUserModalProps) {
  const { ready, addUser,getAccounts } = useApi();
  const [accounts, setAccounts] = useState<Account[] | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  useEffect(() => {
    async function loadAccounts() {
        try {
            const accounts = await getAccounts();
            if (accounts.length > 0) {
                setAccounts(accounts);
            }
        } catch (err) {
            pushNotification("Could not load Account.", "error");
        }
    }

    if (ready) {
        loadAccounts();
    }
  }, [ready]);

  async function handleAddUser() {
    if (!ready) {
      pushNotification("Could not add user.", "error");
      return;
    }

    if (!selectedAccount || !firstname || !lastname || !email) {
      pushNotification("Please fill out all fields.", "error");
      return;
    }

    setbuttonDisabled(true);

    const newUser = {
      firstname: firstname,
      lastname: lastname,
      emailaddress: email,
      customeradmin: isAdmin,
      account_id: selectedAccount.account_id,
    };

    try {
      await addUser(newUser);
      reloadUsers();
      clearUserFields();
      pushNotification("User added.", "success");
    } catch {
      pushNotification("Could not add user.", "error");
    }

    setbuttonDisabled(false);
    onClose();
  }

  function clearUserFields() {
    setFirstname("");
    setLastname("");
    setEmail("");
    setIsAdmin(false);
    setSelectedAccount(null);
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Account</FormLabel>
              <Select 
                value={selectedAccount?.account_id} 
                onChange={(e) => {
                  if (accounts) {
                    setSelectedAccount(accounts.filter((account) => account.account_id === e.target.value)[0]);
                  }
                }} 
                placeholder="Select option"
                isDisabled={accounts === null}
              >
                {accounts === null ? (
                  <option disabled>Loading...</option>
                ) : (
                  <>
                    {accounts.map((account) => (
                      <option key={account.account_id} value={account.account_id}>
                        {account.Account_name}
                      </option>
                    ))}
                  </>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel htmlFor="admin" mb="0">
                Admin
              </FormLabel>
              <Switch
                id="admin"
                isChecked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button backgroundColor='#99CD81' _hover={{ bg: '#6B8F5A' }} color={"white"} colorScheme='blue' mr={3} onClick={handleAddUser} isLoading={buttonDisabled || !ready} isDisabled={buttonDisabled || !ready}>
              Add User
            </Button>
            <Button backgroundColor='#6A7D80' _hover={{ bg: '#4A5759' }} color={"white"} variant='ghost' onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddUserModal;
