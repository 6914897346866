import { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { useAccount, useMsal } from "@azure/msal-react";
import { ColDef, IGroupCellRendererParams, SelectionChangedEvent, FirstDataRenderedEvent, RowDataUpdatedEvent, FilterChangedEvent, IRowNode } from "ag-grid-enterprise";
import { ChartDisplay, SupplyType } from "../../types";
import { getAccessToken } from "../../auth/authFunctions";
import debounce from 'lodash/debounce';
import { CEDLinkRenderer, SupplyLinkRenderer } from "../../components/CellRenderers";

type FilterPanePropTypes = {
    chartDisplay: ChartDisplay;
    onSupplyFilterChange: (supplies: string[]) => void;
}

function SiteSummary({ chartDisplay, onSupplyFilterChange }: FilterPanePropTypes) {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [suppliesData, setSuppliesData] = useState<SupplyType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getSupplies();
    }, [instance, account]);

    const filterResults = (results: SupplyType[]) : SupplyType[] => {
        return results.filter((supply) => supply.supply_type !== "Water");
    } 

    async function getSupplies() {
        if (instance && account && !loading) {
            setLoading(true);
            const accessToken = await getAccessToken(instance, account);
            const response = await fetch(`${import.meta.env.VITE_API_URL}/supplies`, { method: "GET", headers: { Authorization: `Bearer ${accessToken}` }});
            const responseJson = await response.json();
            const filteredResults = filterResults(responseJson)
            setSuppliesData(filteredResults);
            setLoading(false);
        }
    }
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs] = useState<ColDef<SupplyType>[]>([
        { field: "account_name", headerName: "Account Name", rowGroup: true, hide: true, sort: "asc", sortIndex: 0 },
        { field: "site_group", headerName: "Site Group", rowGroup: true, hide: true, sort: "asc", sortIndex: 1 },
        { field: "site_address", headerName: "Site Address", rowGroup: true, hide: true, sort: "asc", sortIndex: 2 },
        { field: "supply_type", headerName: "Supply Type", sort: "asc", sortIndex: 3 },
        { field: "supply_subtype", headerName: "Supply Subtype", sort: "asc", sortIndex: 4 },
        { field: "supply_verbose", headerName: "Supply Number", sort: "asc", sortIndex: 5, cellRenderer: SupplyLinkRenderer, minWidth: 300, cellStyle: {overflow: "visible"} },  
        { field: "supply_supplier", headerName: "Supplier" },
        { field: "contract_end_date", headerName: "Contract End Date", cellRenderer: CEDLinkRenderer, cellDataType: 'date', filter: 'agDateColumnFilter', minWidth: 200 },
        { field: "eb_currentconsumption", headerName: "Current Consumption" },
        { field: "supply_number", headerName: "Supply Number Short", hide: true },
        { field: "ooc", headerName: "OOC", hide: true  }, 
        { field: "eb_meterserialnumber", headerName: "Meter Serial Number", hide: true  },
    ]);

    const defaultColDef: ColDef = useMemo(
            () => ({
                filter: true,
                enableRowGroup: true,
                enableValue: true,
            }),
        []
    );

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            headerName: "Include",
            field: "include",
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
            checkbox: true,
            } as IGroupCellRendererParams,
        };
    }, []);

    const debouncedSelectionChanged = useCallback(debounce((event: SelectionChangedEvent) => {
        const selection = event.api.getSelectedNodes();
        if (selection) {
            const supplies = selection.map(node => node.data.supply_number);
            onSupplyFilterChange(supplies);
        }
    }, 300), []); 

    const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
        debouncedSelectionChanged(event); // Debouncing stops propagation of multiple select events and rolls into single query
    }, [debouncedSelectionChanged]);

    const onRowDataChange = useCallback((event: FirstDataRenderedEvent | RowDataUpdatedEvent) => {
        if (chartDisplay.supplies.length == 0) {
            event.api.selectAll();
        } else {
            event.api.forEachNode(node => {
                if (chartDisplay.supplies.includes(node.data?.supply_number)) {
                    node.setSelected(true);
                }
            });
        }
    }, [chartDisplay.supplies]);

    const onFilterChange = useCallback((event: FilterChangedEvent) => {
        const unfiltered: IRowNode[] = [];
        event.api.forEachNodeAfterFilter(node => {
            unfiltered.push(node.data?.supply_number);
        });
        event.api.forEachNode(node => {
            if (!node.group) {
                const selected : boolean = unfiltered.includes(node.data?.supply_number);
                node.setSelected(selected);
            }
        });
    }, []);

    return (
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: '100%', width: '100%' }} // the Data Grid will fill the size of the parent container
          >
            <AgGridReact<SupplyType>
                rowData={suppliesData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                rowSelection={"multiple"}
                groupSelectsChildren={true}
                suppressAggFuncInHeader={true}
                rowGroupPanelShow={"always"}
                onSelectionChanged={onSelectionChanged}
                onFirstDataRendered={onRowDataChange}
                onRowDataUpdated={onRowDataChange}
                onFilterChanged={onFilterChange}
            />
        </div>
    )
}
  
export default SiteSummary;
