import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";

export function getAccessToken(instance: IPublicClientApplication, account: AccountInfo ) {
    return new Promise(function (resolve, reject) {
      instance
        .acquireTokenSilent({
          scopes: [
            "https://eyesource.onmicrosoft.com/98ee402c-d9a5-481e-b9ce-1e646b830c45/CustomerPortal.User",
          ],
          account: account,
        })
        .then(function (response) {
          resolve(response.accessToken); // Resolve the promise with the access token
        })
        .catch(function (error) {
          console.error("Failed to acquire token silently", error);
          reject(error); // Reject the promise if there's an error
        });
    });
  }