import { SavedView } from "../types";

export const elec : SavedView = {
    name: "elec",
    state: {
        "sideBar": {
            "position": "right",
            "visible": false,
            "openToolPanel": null,
            "toolPanels": {}
        },
        "sort": {
            "sortModel": [
                {
                    "colId": "account_name",
                    "sort": "asc"
                },
                {
                    "colId": "site_group",
                    "sort": "asc"
                },
                {
                    "colId": "site_address",
                    "sort": "asc"
                },
                {
                    "colId": "supply_type",
                    "sort": "asc"
                },
                {
                    "colId": "supply_subtype",
                    "sort": "asc"
                },
                {
                    "colId": "supply_verbose",
                    "sort": "asc"
                }
            ]
        },
        "rowGroup": {
            "groupColIds": [
                "account_name",
                "site_group",
                "site_address"
            ]
        },
        "columnVisibility": {
            "hiddenColIds": [
                "account_name",
                "site_group",
                "site_address",
                "supply_number",
                "ooc",
                "eb_meterserialnumber"
            ]
        },
        "columnSizing": {
            "columnSizingModel": [
                {
                    "colId": "account_name",
                    "width": 200
                },
                {
                    "colId": "site_group",
                    "width": 200
                },
                {
                    "colId": "site_address",
                    "width": 200
                },
                {
                    "colId": "supply_type",
                    "width": 200
                },
                {
                    "colId": "supply_subtype",
                    "width": 200
                },
                {
                    "colId": "supply_verbose",
                    "width": 300
                },
                {
                    "colId": "supply_supplier",
                    "width": 200
                },
                {
                    "colId": "contract_end_date",
                    "width": 200
                },
                {
                    "colId": "eb_currentconsumption",
                    "width": 200
                },
                {
                    "colId": "supply_number",
                    "width": 200
                },
                {
                    "colId": "ooc",
                    "width": 200
                },
                {
                    "colId": "eb_meterserialnumber",
                    "width": 200
                }
            ]
        },
        "columnOrder": {
            "orderedColIds": [
                "account_name",
                "site_group",
                "site_address",
                "supply_type",
                "supply_subtype",
                "supply_verbose",
                "supply_supplier",
                "contract_end_date",
                "eb_currentconsumption",
                "supply_number",
                "ooc",
                "eb_meterserialnumber"
            ]
        },
        "filter": {
            "filterModel": {
                "supply_type": {
                    "filterType": "multi",
                    "filterModels": [
                        null,
                        {
                            "values": [
                                "Electricity"
                            ],
                            "filterType": "set"
                        }
                    ]
                }
            }
        },
        "pagination": {
            "page": 0,
            "pageSize": 100
        }
    }
}

export const gas: SavedView = {
    name: "gas",
    state: {
        "sideBar": {
            "position": "right",
            "visible": false,
            "openToolPanel": null,
            "toolPanels": {}
        },
        "sort": {
            "sortModel": [
                {
                    "colId": "account_name",
                    "sort": "asc"
                },
                {
                    "colId": "site_group",
                    "sort": "asc"
                },
                {
                    "colId": "site_address",
                    "sort": "asc"
                },
                {
                    "colId": "supply_type",
                    "sort": "asc"
                },
                {
                    "colId": "supply_subtype",
                    "sort": "asc"
                },
                {
                    "colId": "supply_verbose",
                    "sort": "asc"
                }
            ]
        },
        "rowGroup": {
            "groupColIds": [
                "account_name",
                "site_group",
                "site_address"
            ]
        },
        "columnVisibility": {
            "hiddenColIds": [
                "account_name",
                "site_group",
                "site_address",
                "supply_number",
                "ooc",
                "eb_meterserialnumber"
            ]
        },
        "columnSizing": {
            "columnSizingModel": [
                {
                    "colId": "account_name",
                    "width": 200
                },
                {
                    "colId": "site_group",
                    "width": 200
                },
                {
                    "colId": "site_address",
                    "width": 200
                },
                {
                    "colId": "supply_type",
                    "width": 200
                },
                {
                    "colId": "supply_subtype",
                    "width": 200
                },
                {
                    "colId": "supply_verbose",
                    "width": 300
                },
                {
                    "colId": "supply_supplier",
                    "width": 200
                },
                {
                    "colId": "contract_end_date",
                    "width": 200
                },
                {
                    "colId": "eb_currentconsumption",
                    "width": 200
                },
                {
                    "colId": "supply_number",
                    "width": 200
                },
                {
                    "colId": "ooc",
                    "width": 200
                },
                {
                    "colId": "eb_meterserialnumber",
                    "width": 200
                }
            ]
        },
        "columnOrder": {
            "orderedColIds": [
                "account_name",
                "site_group",
                "site_address",
                "supply_type",
                "supply_subtype",
                "supply_verbose",
                "supply_supplier",
                "contract_end_date",
                "eb_currentconsumption",
                "supply_number",
                "ooc",
                "eb_meterserialnumber"
            ]
        },
        "filter": {
            "filterModel": {
                "supply_type": {
                    "filterType": "multi",
                    "filterModels": [
                        null,
                        {
                            "values": [
                                "Gas"
                            ],
                            "filterType": "set"
                        }
                    ]
                }
            }
        },
        "pagination": {
            "page": 0,
            "pageSize": 100
        }
    }
}

export const water: SavedView = {
    name: "water",
    state: {
        "sideBar": {
            "position": "right",
            "visible": false,
            "openToolPanel": null,
            "toolPanels": {}
        },
        "sort": {
            "sortModel": [
                {
                    "colId": "account_name",
                    "sort": "asc"
                },
                {
                    "colId": "site_group",
                    "sort": "asc"
                },
                {
                    "colId": "site_address",
                    "sort": "asc"
                },
                {
                    "colId": "supply_type",
                    "sort": "asc"
                },
                {
                    "colId": "supply_subtype",
                    "sort": "asc"
                },
                {
                    "colId": "supply_verbose",
                    "sort": "asc"
                }
            ]
        },
        "rowGroup": {
            "groupColIds": [
                "account_name",
                "site_group",
                "site_address"
            ]
        },
        "columnVisibility": {
            "hiddenColIds": [
                "account_name",
                "site_group",
                "site_address",
                "supply_number",
                "ooc",
                "eb_meterserialnumber"
            ]
        },
        "columnSizing": {
            "columnSizingModel": [
                {
                    "colId": "account_name",
                    "width": 200
                },
                {
                    "colId": "site_group",
                    "width": 200
                },
                {
                    "colId": "site_address",
                    "width": 200
                },
                {
                    "colId": "supply_type",
                    "width": 200
                },
                {
                    "colId": "supply_subtype",
                    "width": 200
                },
                {
                    "colId": "supply_verbose",
                    "width": 300
                },
                {
                    "colId": "supply_supplier",
                    "width": 200
                },
                {
                    "colId": "contract_end_date",
                    "width": 200
                },
                {
                    "colId": "eb_currentconsumption",
                    "width": 200
                },
                {
                    "colId": "supply_number",
                    "width": 200
                },
                {
                    "colId": "ooc",
                    "width": 200
                },
                {
                    "colId": "eb_meterserialnumber",
                    "width": 200
                }
            ]
        },
        "columnOrder": {
            "orderedColIds": [
                "account_name",
                "site_group",
                "site_address",
                "supply_type",
                "supply_subtype",
                "supply_verbose",
                "supply_supplier",
                "contract_end_date",
                "eb_currentconsumption",
                "supply_number",
                "ooc",
                "eb_meterserialnumber"
            ]
        },
        "filter": {
            "filterModel": {
                "supply_type": {
                    "filterType": "multi",
                    "filterModels": [
                        null,
                        {
                            "values": [
                                "Water"
                            ],
                            "filterType": "set"
                        }
                    ]
                }
            }
        },
        "pagination": {
            "page": 0,
            "pageSize": 100
        }
    }
}

export const siteSummaryDefaults : SavedView[] = [elec, gas, water];