import { Box, AbsoluteCenter, Spinner } from "@chakra-ui/react";
import { hhMonthlyDataType } from "../pages/Supply"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

type HHDataProps = {
  hhData: hhMonthlyDataType[] | undefined
}

function HHGraph({hhData}: HHDataProps) {

  let noDataMessage;
  if (hhData === undefined) noDataMessage = <Spinner /> // loading hh data
  if (hhData && hhData.length < 1) noDataMessage = "No consumption data available." // hh data returns empty
  if (noDataMessage) return (
    <Box  position='relative' border={"1px"} borderRadius={"10px"} borderColor={"#6A7D80"} width="100%" height="100%">
      <AbsoluteCenter><p>{noDataMessage}</p></AbsoluteCenter>
    </Box>
  )

  return (
    // !!! XAxis and YAxis currently throw warnings in the console, recharts devs fixing this and if needed we can use alpha build to solve for now
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={hhData}>
        <CartesianGrid stroke="#ccc" />
        <YAxis domain={["dataMin", "dataMax"]} tick={false} orientation="right" />
        <XAxis dataKey="month" />
        <Line type="monotone" dataKey="month_total" stroke="#8884d8" />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default HHGraph