import { Center, Link, SkeletonCircle, Skeleton, Text, Stack, Flex, Box } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useContext, useEffect, useState } from "react";
import { Account, NotificationContextType } from "../../../types";
import { NotificationContext } from "../../../components/NotificationPane";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function ConsultantContact() {
  const { ready, getAccounts, getConsultantImage } = useApi();
  const [ account, setAccount ] = useState<Account | null>(null);
  const [ imageSrc, setImageSrc ] = useState<string | null>(null);
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  const imageFromBuffer = (buffer: number[]) => {
    // Convert the buffer array to a Uint8Array
    const uint8Array = new Uint8Array(buffer);

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'image/png' });

    // Create an Object URL for the Blob
    const imageUrl = URL.createObjectURL(blob);

    // Set the image URL in the state
    setImageSrc(imageUrl);
  }

  const getImage = async(email : string) => {
    try {
      const res = await getConsultantImage(email);
      imageFromBuffer(res.image.data);
    } catch (err) {
      pushNotification("Could not load Image.", "error")
    }
  }

  useEffect(() => {
    async function loadAccount() {
      setAccount(null);
      setImageSrc(null);
      try {
        const account = await getAccounts();
        
        if (account.length > 0) {
          const firstAccount = account[0];
          setAccount(firstAccount);

          if (firstAccount.Consultant_Email) {
            getImage(firstAccount.Consultant_Email);
          }
        }
      } catch (err) {
        pushNotification("Could not load Account.", "error")
      }
    }

    if (ready)
      loadAccount();

  }, [ready, getAccounts]);

  return (
    <Box h={'300'} className="custom-scroll" overflowY="scroll">
      <Flex h={"100%"} flexDirection={"column"} justifyContent={"center"}>
        <Center padding={4}>
          {imageSrc &&
            <img style={{ borderRadius: "150px", width: "150px" }} src={imageSrc} alt="Headshot" />
          }
          {!imageSrc &&
            <SkeletonCircle size={"150px"} />
          }
        </Center>
        <Center>
          <Stack>
            {account ? <Text align={"center"}>{account.Consultant_Name}</Text> : <Skeleton height={"36px"} width={"100px"}/>}
            {account ? <Text align={"center"}>eyebright Ltd</Text> : <Skeleton height={"36px"} width={"100px"}/>}
            {account ? <Text align={"center"}><Link isExternal href={"tel:"+account.Consultant_phone}>{account.Consultant_phone}</Link></Text> : <Skeleton height={"36px"} width={"100px"}/>}
            {account ? <Text align={"center"}><Link isExternal href={"mailto:"+account.Consultant_Email}>{account.Consultant_Email}</Link></Text> : <Skeleton height={"36px"} width={"100px"}/>}
            {account?.Consultant_booking &&
              <Link href={account.Consultant_booking} isExternal>
                Make Booking <ExternalLinkIcon mx='2px' />
              </Link>
            }
          </Stack>
        </Center>
      </Flex>
    </Box>
  )
}

export default ConsultantContact