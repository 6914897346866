import moment from "moment";
import * as Enums from "./Enums";
import { ChartDisplay, Filters } from "../types";

export const defaultChartDisplay : ChartDisplay = {
    overlays: [],
    supplies: [],
    chartType: Enums.ChartTypeEnum.BAR,
    grouping: Enums.GroupEnum.SUPPLY_TYPE,
}

export const defaultFilters : Filters = {
    period: Enums.PeriodEnum.MONTHLY,
    startDate: getDefaultEndDate(Enums.PeriodEnum.MONTHLY),
    endDate: getDefaultStartDate(Enums.PeriodEnum.MONTHLY),
};

export function getDefaultEndDate(period: Enums.PeriodEnum): Date {
    const today = moment();
    const lastSunday = moment().day(0);

    switch (period) {
        case Enums.PeriodEnum.HOURLY:
            return today.subtract(12, 'hours').toDate();
        case Enums.PeriodEnum.DAILY:
            return today.subtract(12, 'days').toDate();
        case Enums.PeriodEnum.WEEKLY:
            return lastSunday.subtract(12, 'weeks').toDate();
        case Enums.PeriodEnum.MONTHLY:
            return today.subtract(12, 'months').toDate();
        case Enums.PeriodEnum.YEARLY:
            return today.subtract(12, 'years').toDate();
        default:
            return today.toDate();
    }
}

export function getDefaultStartDate(period: Enums.PeriodEnum): Date {
    const today = moment();
    const lastSunday = moment().day(0);

    switch (period) {
        case Enums.PeriodEnum.WEEKLY:
            return lastSunday.toDate();
        case Enums.PeriodEnum.DAILY:
        case Enums.PeriodEnum.HOURLY:
        case Enums.PeriodEnum.MONTHLY:
        case Enums.PeriodEnum.YEARLY:
        default:
            return today.toDate();
    }
}