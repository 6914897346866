import { useState, useEffect, useMemo, useCallback, useContext } from "react"
import { UserType, NotificationContextType } from "../../types";
import {
  Button,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import AddUserModal from "./AddUserModal";
import UpdateUserModal from "./UpdateUserModal";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import AdminRoute from "../../util/AdminRoute";
import { useApi } from "../../hooks/useApi";
import { NotificationContext } from '../../components/NotificationPane'; 
import BaseTemplate from "../../components/BaseTemplate";

function TickCrossRenderer(params: CustomCellRendererProps) {
  return (
    <>
      {params.value === true && <CheckIcon color="green" />}
      {params.value === false && <CloseIcon color="red" />}
    </>
  );
}

function StatusRenderer(params: CustomCellRendererProps) {
  if (params.value)
    return "active";
  else
    return "pending";
}

function Admin() {
  const [users, setUsers] = useState<UserType[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const [sending, setSending] = useState<boolean>(false);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { ready, resendInvite, getUsers } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  const callResendInvite = useCallback(async (user: UserType) => {
    if (ready) {
      try {
        setSending(true);
        await resendInvite(user);
        //pushNotification("Functionality not implemented.", "error"); 
      } catch (err) {
        pushNotification("Could not resend invite.", "error");
      } finally {
        setSending(false);
      }
    }
  }, [ready, resendInvite]);

  const InviteRenderer = useCallback((params: CustomCellRendererProps) => {
    if (!params.value)
      return (
        <Button
          isDisabled={!ready || sending}
          isLoading={!ready || sending}
          ref={(ref) => {
            if (!ref) return;
  
            ref.onclick = (e) => {
              console.log(params.data)
              e.stopPropagation();
              callResendInvite(params.data);
            };
          }}
        >
          Resend Invite
        </Button>
      )
  }, [ready, sending, callResendInvite]);

  const colDefs = useMemo<ColDef<UserType>[]>(() => [
    { field: "account_name", headerName: "Account Name", rowGroup: true, hide: true, sort: "asc", sortIndex: 0 },
    { field: "emailaddress", headerName: "Email Address", sort: "asc", sortIndex: 1 },
    { field: "firstname", headerName: "Firstname" },
    { field: "lastname", headerName: "Lastname" },
    { field: "customeradmin", headerName: "Admin", cellRenderer: TickCrossRenderer },
    { field: "readaccess", headerName: "Read Access", cellRenderer: TickCrossRenderer },
    { field: "last_activity", headerName: "Status", cellRenderer: StatusRenderer },
    { field: "last_activity", headerName: "", cellRenderer: InviteRenderer },
  ], [InviteRenderer]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      filter: true,
      enableRowGroup: true,
      enableValue: true,
    }),
    []
  );

  const loadUsers = useCallback(async () => {
    const response = await getUsers();
    setUsers(response);
  }, [getUsers])

  useEffect(() => {
    loadUsers();
  }, [loadUsers, ready]);

  function updateUser(user: UserType) {
    setSelectedUser(user);
    onEditOpen();
  }

  function rowClick(event: RowClickedEvent<UserType>) {
    if (event.data)
      updateUser(event.data)
  }

  //if (users.length === 0) return <p>Loading...</p>
  return (
    <AdminRoute>
      <AddUserModal 
        isOpen={isAddOpen} 
        onClose={onAddClose} 
        reloadUsers={loadUsers}
      />

      <UpdateUserModal
        isOpen={isEditOpen}
        onClose={onEditClose}
        user={selectedUser}
        reloadUsers={loadUsers}
      />

      <BaseTemplate fitToWindow={true}>
        <BaseTemplate.Header>
          <Button backgroundColor='#99CD81' _hover={{ bg: '#6B8F5A' }} color={"white"} onClick={onAddOpen}>Add User</Button>
        </BaseTemplate.Header>

        
        <BaseTemplate.Body>
          <Box w="100%" h="100%">
            <div
            className="ag-theme-quartz" // applying the Data Grid theme
            style={{ height: '100%', width: '100%' }} // the Data Grid will fill the size of the parent container
            >
              <AgGridReact<UserType>
                  onRowClicked={rowClick}
                  rowData={users}
                  columnDefs={colDefs}
                  groupDisplayType={'groupRows'}
                  rowGroupPanelShow={"always"}
                  defaultColDef={defaultColDef}
                  autoSizeStrategy={{type: 'fitGridWidth'}}
                  groupDefaultExpanded={1}
              />
            </div>
          </Box>
        </BaseTemplate.Body>
      </BaseTemplate>
    </AdminRoute>
  )
}

export default Admin
