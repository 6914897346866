import React from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { StateUpdatedEvent } from 'ag-grid-community';
import { usePeristentGrid } from '../../hooks/usePersistentGrid';

type PersistentGridProps<TData> = AgGridReactProps<TData>;

const PersistentGrid = <TData,>({...agGridProps }: PersistentGridProps<TData>): React.ReactElement => {
    const { gridState, updateGridState, resetKey } = usePeristentGrid();

    function stateUpdated(event: StateUpdatedEvent) {
        const state = event.state;
        updateGridState(state);
    }

    return (
        <AgGridReact<TData>
            key={resetKey}  // Changing this key will trigger a full re-render
            {...agGridProps}
            onStateUpdated={stateUpdated}
            initialState={gridState}
        />
    );
};

export default PersistentGrid;
