import React, { useState, ReactNode, useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  HStack,
  VStack,
  useDisclosure,
  useBreakpointValue,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { FaBuilding, FaChartLine, FaFile, FaUser, FaArrowRightFromBracket, FaWrench, FaHouse, FaPenToSquare, FaThumbtack, FaCalendarDays, FaEye, FaBug  } from 'react-icons/fa6';
import { Link, useLocation } from 'wouter';
import { useAuth } from '../hooks/useAuth';
import eyebrightFlower from '../assets/eyebright-flower.png';
import eyebrightLogo from '../assets/eyebright-logo.png';
import RenewalsDrawer from './RenewalsDrawer';
import { FaBars } from "react-icons/fa6";
import TicketsDrawer from './TicketsDrawer';
import ActingAsModal from './ActingAsModal';
import { useCurrentUser } from '../contexts/CurrentUserContext';

const menuOptions = [
  {
    name: 'Home',
    href: '/',
    icon: FaHouse,
  },
  {
    name: 'Charts',
    href: '/hh-dashboard',
    icon: FaChartLine,
  },
  {
    name: 'Site Summary',
    href: '/site-summary',
    icon: FaBuilding,
  },
  {
    name: 'Files',
    href: '/files',
    icon: FaFile,
  }
]

const iconSize = 4;

interface NavItemProps {
  icon: React.ElementType;
  collapsed: boolean;
  href?: string;
  text: string;
  color: string;
  ticketsOpen?: boolean;
  renewalsOpen?: boolean;
  onClick?: () => void;
}

interface SidebarProps {
  children: ReactNode;
}

interface PinButtonProps {
  onToggle: () => void;
  pinned: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ icon, collapsed, href, text, color, ticketsOpen, renewalsOpen, onClick }) => {
  const [location] = useLocation();
  const isActive = href === location || ticketsOpen || renewalsOpen; // shows active page as href and ticekts/renewals when drawer opened

  return (
    <Link href={href || "#"}>
      <HStack 
        m={2} 
        onClick={onClick} 
        bg={isActive ? "white" : color} 
        p={3}
        h={8}
        w={collapsed ? "fit-content" : "auto"}
        borderRadius="md"
      >
        {icon && (
          <Icon
            boxSize={iconSize}
            as={icon}
            color={isActive ? color : "white"}
          />
        )}
        {!collapsed && (
          <Text fontSize='md' color={isActive ? color : "white"}>
            {text}
          </Text>
        )}
      </HStack>
    </Link>
  );
};

const PinButton: React.FC<PinButtonProps> = ({ onToggle, pinned }) => {
  return (
    <Box m={2} p={2} onClick={onToggle}>
      <Icon
        boxSize={iconSize}
        as={FaThumbtack}
        transform={pinned ? "rotate(90deg)" : "rotate(0deg)"}
        transition="transform 0.2s ease-in-out"
        color="white"
      />
    </Box>
  )
};

const DynamicMenu: React.FC<SidebarProps> = ({ children }) => {
  const type = useBreakpointValue(
    {
      base: 'BURGER',
      sm: 'BURGER',
      md: 'SIDEBAR',
    }
  )

  if (type === 'SIDEBAR') {
    return (
      <Sidebar>
        {children}
      </Sidebar>
    )
  }

  if (type === 'BURGER') {
    return (
      <BurgerMenu>
        {children}
      </BurgerMenu>
    )
  }
}

const BurgerMenu: React.FC<SidebarProps> = ({ children }) => {
  const { isOpen: isTicketsOpen, onToggle: onTicketsToggle, onClose: onTicketsClose } = useDisclosure(); // tracks status of tickets drawer
  const { isOpen: isRenewalsOpen, onToggle: onRenewalsToggle, onClose: onRenewalsClose } = useDisclosure(); // tracks status of renewals drawer
  const { isOpen: isActingAsOpen, onToggle: onActingAsToggle, onClose: onActingAsClose } = useDisclosure(); // tracks status of acting as modal
  return (
    <>
      <TicketsDrawer  isOpen={isTicketsOpen} onClose={onTicketsClose} />
      <RenewalsDrawer isOpen={isRenewalsOpen} onClose={onRenewalsClose} />
      <ActingAsModal isOpen={isActingAsOpen} onClose={onActingAsClose}></ActingAsModal>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Menu'
          size='lg'
          icon={<FaBars />}
          position="fixed" 
          top="0" 
          left="0" 
          m={4} 
          zIndex="1"
        />
        <MenuList>
          {menuOptions.map((option) => (
              <Link href={option.href} key={option.name}>
                <MenuItem>
                  {option.name}
                </MenuItem>
              </Link>
          ))}
          <Link href="#" onClick={onTicketsToggle} key={"Tickets"}><MenuItem>Tickets</MenuItem></Link>
          <Link href="#" onClick={onRenewalsToggle} key={"Contract Renewals"}><MenuItem>Contract Renewals</MenuItem></Link>
          <Link href="#" onClick={onActingAsToggle} key="Acting As"><MenuItem>Acting As</MenuItem></Link>
        </MenuList>
      </Menu>
      <Box>
        {children}
      </Box>
    </>
  )
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [sidebarWidth, setSidebarWidth] = useState<number>(0);
  const [collapsed, setCollapsed] = useState(true);
  const [pinned, setPinned] = useState(false);
  const { logout } = useAuth();
  const { user, isAdmin } = useCurrentUser();

  useEffect(() => {
    if (sidebarRef.current && (pinned || collapsed)) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, [collapsed, pinned]);

  const onMouseLeave = () => {
    if (!pinned)
      setCollapsed(true);
  }

  const { onClose } = useDisclosure(); // admin selection uses
  const { isOpen: isTicketsOpen, onToggle: onTicketsToggle, onClose: onTicketsClose } = useDisclosure(); // tracks status of tickets drawer
  const { isOpen: isRenewalsOpen, onToggle: onRenewalsToggle, onClose: onRenewalsClose } = useDisclosure(); // tracks status of renewals drawer
  const { isOpen: isActingAsOpen, onToggle: onActingAsToggle, onClose: onActingAsClose } = useDisclosure(); // tracks status of acting as modal

  return (
    <>
      <TicketsDrawer  isOpen={isTicketsOpen} onClose={onTicketsClose} />
      <RenewalsDrawer isOpen={isRenewalsOpen} onClose={onRenewalsClose} />
      <ActingAsModal isOpen={isActingAsOpen} onClose={onActingAsClose}></ActingAsModal>
      <Box>
        <Box
          ref={sidebarRef}
          display={"flex"}
          bg='#6A7D80'
          height="100vh"
          position="fixed"
          top="0"
          left="0"
          zIndex="100000"
          onMouseEnter={() => setCollapsed(false)}
          onMouseLeave={onMouseLeave}
          className='custom-scroll'
        >
          <Flex
            direction="column"
            justifyContent="space-between"
            height="100%"
          >
          <VStack align='left'>
            <HStack justifyContent="space-between">
              <Box m={2} p={2}>
                {collapsed
                  ? <img src={eyebrightFlower} alt="Eyebright Flower" width="30" />
                  : <img src={eyebrightLogo} alt="Eyebright Logo" width="97" />
                }
              </Box>
              {!collapsed &&
                <PinButton pinned={pinned} onToggle={() => setPinned(!pinned)} />
              }
            </HStack>
            {
              menuOptions.map((option) => (
                <NavItem
                  key={option.name}
                  href={option.href}
                  text={option.name}
                  icon={option.icon}
                  color='#6A7D80'
                  collapsed={collapsed}
                  onClick={()=>{onRenewalsClose(); onTicketsClose();}} // closes any open renewals or tickets panes if clicked
                />
              ))
            }
            <NavItem color='#6A7D80' icon={FaPenToSquare} collapsed={collapsed} onClick={onTicketsToggle} ticketsOpen={isTicketsOpen} text="Tickets" />
            <NavItem color='#6A7D80' icon={FaCalendarDays} collapsed={collapsed} onClick={onRenewalsToggle} renewalsOpen={isRenewalsOpen} text="Contract Renewals" />
          </VStack>
          {/* !!! need to hide/show the below based on user (only avaialble for consultants who have at least 1 act as) */}
          <Box marginTop={'auto'}><NavItem color='#6A7D80' icon={FaEye} collapsed={collapsed} onClick={onActingAsToggle} renewalsOpen={isActingAsOpen} text="(Acting As)" /></Box>
          <VStack align='left' bg="#566669">
            <NavItem color='#566669' icon={FaUser} collapsed={collapsed} text={user?.firstname || "User"} />
            <NavItem color='#566669' onClick={logout} icon={FaArrowRightFromBracket} collapsed={collapsed} text="Log Out" />
            {isAdmin &&
              <NavItem color='#566669' icon={FaWrench} collapsed={collapsed} onClick={onClose} href="/admin" text="Admin" />
            }
            <NavItem color='#566669' icon={FaBug} collapsed={collapsed} text={"Bug Report"} onClick={() => window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=uw3-VWNTMkSweQqCaCSzLbZl_UfE_nlDrB6zzExfEqZUN1cwU1BNRzdUUEEwOTgzMlBSUlNQRUxDUy4u", '_blank')} />
          </VStack>
        </Flex>
      </Box>
      {sidebarWidth &&
        <Box backgroundColor={"purple.10"} ml={`${sidebarWidth}px`}>
          {children}
        </Box>
      }
      </Box>
    </>
    
  );
};

export default DynamicMenu;
