import { Box, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

type ComponentWithChildrenProps = {
  children: ReactNode;
  fitToWindow?: boolean; // New optional prop to control window fitting behavior
};

// Header component
function Header({ children }: ComponentWithChildrenProps) {
  return (
    <Box 
        backgroundColor={"white"}
        p={2}
        border={"1px"} 
        borderRadius={"12px"} 
        borderColor={"#E2E8F0" }
        boxShadow={"md"}
    >
        {children}
    </Box>
  )
}

// Body component that conditionally fills the remaining space
function Body({ children }: ComponentWithChildrenProps) {
  return (
    <Box
        backgroundColor={"white"}
        p={2}
        border={"1px"} 
        borderRadius={"12px"} 
        borderColor={"#E2E8F0" }
        boxShadow={"md"}
        h="100%"
    >
      {children}
    </Box>
  );
}

// BaseTemplate component that conditionally applies full height when fitToWindow is true
function BaseTemplate({ children, fitToWindow }: ComponentWithChildrenProps) {
  const height = fitToWindow ? "100vh" : "auto";

  return (
    <VStack
      align="stretch"
      spacing={4}
      p={4}
      h={height}
    >
      {children}
    </VStack>
  );
}

BaseTemplate.Header = Header;
BaseTemplate.Body = Body;

export default BaseTemplate;
